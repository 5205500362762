<template>
  <div>
    <b-row>
      <b-col>
        <b-card>

          <b-row>
          <h4 class="ml-1 mb-2">Data SPB</h4>
            <b-col cols="12">
              <form-label
                label="Kode SPB"
                :content="model.number"
              />
            </b-col>
            <b-col cols="12">
              <form-label
                label="Tanggal SPB"
                :content="model.date"
              />
            </b-col>
            <b-col cols="12">
              <form-label
                label="Region"
                :content="model.region != null ? model.region.name : ''"
              />
            </b-col>
            <b-col cols="12">
              <form-label
                label="Judul Kontrak"
                :content="model.contract_subject"
              />
            </b-col>
            <b-col cols="12">
              <form-label
                label="Nomor Kontrak"
                :content="model.contract_number"
              />
            </b-col>
            <b-col cols="12">
              <form-label
                label="Vendor"
                :content="model.vendor != null ? model.vendor.name : '' "
              />
            </b-col>
            <b-col cols="12">
              <form-label
                label="Tanggal Waktu Pengiriman"
                :content="model.shipping_date"
              />
            </b-col>
            <b-col cols="12">
              <div class="row mt-1">
                <div class="col-5">
                  Status {{$route.meta.navActiveLink}}
                </div>
                <div class="col">
                  <span
                    v-if="model.status == 'created'"
                    class="badge rounded-pill bg-info"
                  >Pembuatan SPB</span>
                  <span
                    v-if="model.status == 'prepared'"
                    class="badge rounded-pill bg-success"
                  >Penyiapan Unit Oleh Vendor</span>
                  <span
                    v-if="model.status == 'applied'"
                    class="badge rounded-pill bg-info"
                  >Pengajuan SPB</span>
                  <span
                    v-if="model.status == 'delivered'"
                    class="badge rounded-pill bg-info"
                  >Pengiriman SPB Ke Vendor</span>
                  <span
                    v-if="model.status == 'distributed'"
                    class="badge rounded-pill bg-info"
                  >Pengiriman Unit dari Vendor</span>
                  <span
                    v-if="model.status == 'viewed'"
                    class="badge rounded-pill bg-success"
                  >SPB Ditinjau</span>
                  <span
                    v-if="model.status == 'approved'"
                    class="badge rounded-pill bg-success"
                  >SPB Disetujui</span>
                  <span
                    v-if="model.status == 'returned'"
                    class="badge rounded-pill bg-success"
                  >SPB Direvisi</span>
                  <span
                    v-if="model.status == 'canceled'"
                    class="badge rounded-pill bg-warning"
                  >Dibatalkan</span>
                  <span
                    v-if="model.status == 'received'"
                    class="badge rounded-pill bg-success"
                  >Pengiriman Unit</span>
                  <span
                    v-if="model.status == 'request_rikmatek'"
                    class="badge rounded-pill bg-success"
                  >Pengajuan Rikmatek</span>
                  <span
                    v-if="model.status == 'send_letter_distribute'"
                    class="badge rounded-pill bg-success"
                  >Sudah Pemeriksaan</span>
                  <span
                    v-if="model.status == 'bapp_published'"
                    class="badge rounded-pill bg-success"
                  >BAPP Dipublish</span>
                  <span
                    v-if="model.status == 'bapp_requested'"
                    class="badge rounded-pill bg-success"
                  >BAPP Direquest</span>
                </div>
              </div>
            </b-col>
            <b-col
              cols="12"
            >
            <hr>
              <div v-if="loading">
                <b-spinner small />
                Loading...
              </div>
              <div v-else>
                <!-- <a :href="linkprint" target="_blank" class="btn btn-info mr-1">Print BAPP</a> -->
                <!-- <a :href="linkprintBast" target="_blank" class="btn btn-info mr-1">Print BAST</a> -->
                <b-button
                  v-if="optionPeriodeDocument.length > 0"
                  class="mr-1"
                  variant="info"
                  type="button"
                  @click="showPeriodeDoc('bapp')"
                >
                  <span>Print BAPP</span>
                </b-button>
                <b-button
                  v-if="optionPeriodeDocument.length > 0"
                  class="mr-1"
                  variant="info"
                  type="button"
                  @click="showPeriodeDoc('bast')"
                >
                  <span>Print BAST</span>
                </b-button>
                <b-button
                  v-if="'bapp_requested,bapp_published'.includes(model.status)"
                  class="mr-1"
                  variant="success"
                  type="button"
                  v-b-modal="'modal-select-periode'"
                >
                  
                  <span>Setujui</span>
                </b-button>
                
                <!-- <b-button
                  style="margin-right: 5px;margin-top: 5px;"
                  variant="info"
                  type="button"
                  @click.prevent="cancel"
                >
                  Kembali
                </b-button> -->
              </div>
              
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h4 class="mb-2"><feather-icon
                    icon="ListIcon"
                  /> Proses</h4>
          <view-time-line 
            :fields="data_time_line"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card v-if="renderComp">
          <b-tabs
          >
            <b-tab
              active
            >
              <template v-slot:title>
                <feather-icon
                    icon="HomeIcon"
                  />
                  Detail Item Barang
              </template>
              <item 
              :posturl="posturl"
              />
            </b-tab>
            <b-tab
            >
              <template v-slot:title>
                <feather-icon
                    icon="FileIcon"
                  />
                  Dokumen
              </template>

              <document 
              :posturl="posturl"
              />
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <feather-icon
                    icon="FolderIcon"
                  />
                  Daftar Kendaraan
              </template>
              <vehicle 
              :posturl="posturl"
              />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      :id="'modal-select-periode'"
      centered
      title="Periode Persetujuan BAPP & BAST"
      hide-footer
    >
      <div>
        <b-row>
          <b-col cols="12">
            <form-v-select
              v-model="periode_date"
              :selected="periode_date"
              :item_text="'periode'"
              rules="required"
              label="Periode Dokumen"
              placeholder="Select Periode Dokumen"
              :options="optionPeriode"
            />
          </b-col>
          <b-col cols="12">
            <hr>
            <b-form-group
              label-cols-md="3"
              label=""
            >
              <b-button
                style="float: right;"
                class="mr-1"
                size="sm"
                variant="primary"
                type="button"
                @click.prevent="validationForm"
              >
                <span v-if="loading">
                  <b-spinner small />
                  Loading...
                </span>
                <span v-else>Simpan</span>
              </b-button>
              <b-button
                style="float: right;"
                class="mr-1"
                size="sm"
                variant="warning"
                type="button"
                @click.prevent="$bvModal.hide('modal-select-periode')"
              >
                <span>Batal</span>
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      :id="'modal-select-periode-document'"
      centered
      title="Periode Dokumen"
      hide-footer
    >
      <div>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col cols="12">
                <form-v-select
                  v-model="periode_date_document"
                  :selected="periode_date_document"
                  item_text="periode"
                  rules="required"
                  label="Periode Dokumen"
                  placeholder="Select Periode Dokumen"
                  :options="optionPeriodeDocument"
                />
              </b-col>
              <b-col cols="12">
                <hr>
                <b-form-group
                  label-cols-md="3"
                  label=""
                >
                  <b-button
                    style="float: right;"
                    class="mr-1"
                    size="sm"
                    variant="primary"
                    type="button"
                    @click.prevent="validationFormDoc"
                  >
                    <span v-if="loading">
                      <b-spinner small />
                      Loading...
                    </span>
                    <span v-else>Print</span>
                  </b-button>
                  <b-button
                    style="float: right;"
                    class="mr-1"
                    size="sm"
                    variant="warning"
                    type="button"
                    @click.prevent="$bvModal.hide('modal-select-periode-document')"
                  >
                    <span>Batal</span>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>

import {BRow, BCol, BButton,BSpinner,BCard,BTabs, BTab, BFormGroup} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import FormLabel from '@/views/base/form/FormLabel.vue'
import ViewTimeLine from './ViewTimeLine.vue'
import Document from './document/Index.vue'
import Item from './item/Index.vue'
import Vehicle from './vehicle/Index.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'

export default {
  components: {

    BRow,
    BCol,
    BButton,
    BSpinner,
    BCard,
    FormLabel,
    ViewTimeLine,BTabs, BTab,
    Document,
    Item,
    Vehicle,
    FormVSelect,
    BFormGroup
  },
  data() {
    return {
      loading :false,
      model:{},
      baseroute:'spb',
      posturl:'/spb',
      tombol_hide:true,
      ajukan:'',
      renderComp:true,
      title_modal:null,
      data_ajukan:[],
      data_item:{},
      data_time_line:{},
      linkprint: null,
      linkprintBast:null,
      periode_date:null,
      optionPeriode:[],
      periode_date_document:null,
      optionPeriodeDocument:[],
      type_doc:null
    }
  },
  beforeMount(){
    this.$http.get('/staff').then(ref=>{
        this.data_ajukan = ref.data.data
    })
  },
  mounted(){
    this.getData()
    this.getDataItem()
    this.fetchDataPayment()
  },
  methods: {
    fetchDataPayment(){
      this.$http.get('spb/'+this.$route.params.id+'/document?length=').then(res=>{
        let data = res.data.data
        this.optionPeriode = data.filter(x => x.status == 'request')
        this.optionPeriodeDocument = data
      })
    },
    getData(){
      this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
        this.model = res.data
        this.data_time_line = res.data.tracks
        // this.$http.get('spb/'+res.data.id+'/bapp/print').then(res=>{
        //   this.linkprint = res.data.filename
        // })
        // this.$http.get('spb/'+res.data.id+'/bast/print').then(res=>{
        //   this.linkprintBast = res.data.filename
        // })
      })
     },
     getDataItem(){
        this.$http.get(this.posturl+'/'+this.$route.params.id+'/item').then(res=>{
          this.data_item = res.data.data
        })
     },
     confirmPublish(model){
      this.$bvModal
        .msgBoxConfirm('Apa anda yakin menyetujui data ini?', {
          title: 'Konfirmasi',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'primary',
          okTitle: 'Iya',
          cancelTitle: 'Batal',
        })
        .then(confirm => {
          if(confirm){
            this.$http.put('spb/'+model.id+'/bapp/publish')
              .then(() => {
                // this.$refs.baseinfo.getData()
                this.getData()
                this.renderComp = false
                this.$nextTick().then(() => {
                this.renderComp = true
                });
            })
          }
        })
    },
    editData(data){
      this.$router.push({ name: this.baseroute+'-edit',params : { id: data.id} })
    },
    confirmDelete(data){
      this.$bvModal
        .msgBoxConfirm(data.contract_subject != null ? 'Anda Ingin Menghapus Data '+data.contract_subject +' ini ? Karena akan berhubungan dengan data lain ?' : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?' , {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.posturl+'/'+data.id)
              .then(() => {
                this.$router.push({ name: this.baseroute })

            })
            .catch(err=>{
              this.errorSubmit(err)
            })
          }
        })
    },
    showKirim(){
      this.title_modal = "Konfirmasi"
      this.$refs['my-modal-kirim'].show();
    },
    showPrepare(){
      this.title_modal = "Konfirmasi"
      this.$refs['my-modal-prepare'].show();
    },
    showSetuju(){
      this.title_modal = "Konfirmasi"
      this.$refs['my-modal-setuju'].show();
    },
    showTerima(){
      this.title_modal = "Konfirmasi"
      this.$refs['my-modal-terima'].show();
    },
    showDistribute(){
      this.title_modal = "Konfirmasi"
      this.$refs['my-modal-distribute'].show();
    },
    showAjukan(){
      this.getDataItem()
      this.title_modal = "Pengajuan SPB"
      this.$refs['my-modal-form'].show();
    },
    showBatalkan(){
      this.title_modal = "Konfirmasi"
      this.$refs['my-modal-batalkan'].show();
    },
    showPeriodeDoc(type){
      this.type_doc = type
      this.$bvModal.show('modal-select-periode-document')
    },
    submitAjukan(){
      this.$http.put(this.posturl+'/'+this.$route.params.id+'/apply',this.ajukan).then(()=>{
          this.ajukan = ''
          this.getData()
          this.$refs['my-modal-form'].hide();
          this.renderComp = false
          this.$nextTick().then(() => {
            this.renderComp = true
          });
          this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Pengajuan SPB sukses',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitBatalkan(){
      this.$http.put(this.posturl+'/'+this.$route.params.id+'/cancel').then(()=>{
          this.getData()
          this.$refs['my-modal-batalkan'].hide();
          this.renderComp = false
          this.$nextTick().then(() => {
            this.renderComp = true
          });
          this.$toast({
          component: ToastificationContent,
          props: {
            title: 'SPB berhasil dibatalkan',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitRevisi(){
      this.$http.put(this.posturl+'/'+this.$route.params.id+'/return').then(()=>{
          this.getData()
          this.renderComp = false
          this.$nextTick().then(() => {
            this.renderComp = true
          });
          this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Revisi berhasil',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitKirim(){
      this.$http.put(this.posturl+'/'+this.$route.params.id+'/deliver').then(()=>{
          this.getData()
          this.$refs['my-modal-kirim'].hide();
          this.renderComp = false
          this.$nextTick().then(() => {
            this.renderComp = true
          });
          this.$toast({
          component: ToastificationContent,
          props: {
            title: 'SPB berhasil dikirim ke Vendor',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitTerima(){
      this.$http.put(this.posturl+'/'+this.$route.params.id+'/receive').then(()=>{
          this.getData()
          this.$refs['my-modal-terima'].hide();
          this.renderComp = false
          this.$nextTick().then(() => {
            this.renderComp = true
          });
          this.$toast({
          component: ToastificationContent,
          props: {
            title: 'SPB berhasil diterima',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitPrepare(){
      this.$http.put(this.posturl+'/'+this.$route.params.id+'/prepare').then(()=>{
          this.getData()
          this.$refs['my-modal-prepare'].hide();
          this.renderComp = false
          this.$nextTick().then(() => {
            this.renderComp = true
          });
          this.$toast({
          component: ToastificationContent,
          props: {
            title: 'SPB telah lengkap',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitSetuju(){
      this.$http.put(this.posturl+'/'+this.$route.params.id+'/approve').then(()=>{
          this.getData()
          this.$refs['my-modal-setuju'].hide();
          this.renderComp = false
          this.$nextTick().then(() => {
            this.renderComp = true
          });
          this.$toast({
          component: ToastificationContent,
          props: {
            title: 'SPB berhasil disetujui',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitDistribute(){
      this.$http.put(this.posturl+'/'+this.$route.params.id+'/distribute').then(()=>{
          this.getData()
          this.$refs['my-modal-distribute'].hide();
          this.renderComp = false
          this.$nextTick().then(() => {
            this.renderComp = true
          });
          this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Pengiriman unit berhasil',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    hideModal(){
      this.$refs['my-modal-form'].hide()
      this.$refs['my-modal-batalkan'].hide()
      this.$refs['my-modal-setuju'].hide()
      this.$refs['my-modal-kirim'].hide()
      this.$refs['my-modal-prepare'].hide();
      this.$refs['my-modal-terima'].hide();
      this.$refs['my-modal-distribute'].hide();
      this.$bvModal.hide('modal-select-periode-document')
    },
    cancel(){
        this.$router.push({ name:this.$route.meta.pageActive })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$refs['my-modal-form'].hide()
      this.$refs['my-modal-batalkan'].hide()
      this.$refs['my-modal-setuju'].hide()
      this.$refs['my-modal-kirim'].hide()
      this.$refs['my-modal-prepare'].hide();
      this.$refs['my-modal-terima'].hide();
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    validationForm(){
      if(!this.periode_date)
        return this.$bvToast.toast('Periode does not exist', {
          title: 'Form Error',
          solid: true,
          variant:'danger'
        })

      this.loading = true
      this.$http.post(this.posturl+'/'+this.periode_date.spb_id+'/document/'+this.periode_date.id+'/approve')
      .then(res=>{
        this.loading = false
        this.fetchDataPayment()
        this.getData()
        this.$bvModal.hide('modal-select-periode')
        this.periode_date = null
        return this.$bvToast.toast('Approve data berhasil', {
          title: 'Success',
          solid: true,
          variant:'success'
        })
      })
      .catch(err=>{
        const message = err.response.data.message
        return this.$bvToast.toast(message?message:'', {
          title: 'Error',
          solid: true,
          variant:'danger'
        })
      })
      .finally(() => {this.loading = false})
    },
    validationFormDoc(){
      if(!this.periode_date_document)
        return this.$bvToast.toast('Periode does not exist', {
          title: 'Form Error',
          solid: true,
          variant:'danger'
        })

      this.loading = true
      this.$http.get('spb/'+this.$route.params.id+'/'+this.type_doc+'/print', {params:{periode:this.periode_date_document.periode}})
      .then(res => {
        this.loading = false
        this.fetchDataPayment()
        this.getData()
        this.$bvModal.hide('modal-select-periode-document')
        this.periode_date_document = null
        if(res.data.filename){
            window.open(res.data.filename)
        }
      })
      .catch(err=>{
        const message = err.response.data.message
        return this.$bvToast.toast(message?message:'', {
          title: 'Error',
          solid: true,
          variant:'danger'
        })
      })
      .finally(() => {this.loading = false})
    },
  }
}
</script>
